import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Button, message } from "antd";
import { images } from "../assets/images";
import {
  MdDashboard,
  MdMoney,
  MdOutlineSupervisedUserCircle,
  MdOutlineWallet,
  MdReport,
  MdRequestPage,
} from "react-icons/md";
import SubMenu from "antd/es/menu/SubMenu";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { logout } from "../Views/Auth/Login/AuthapiFunctions";
const { Header, Sider, Content } = Layout;
const BasicLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 600 ? setCollapsed(true) : setCollapsed(false);
    };
    window.addEventListener("resize", handleResize);
    const stored = localStorage.getItem("selected");
    if (stored) {
      setSelectedMenu(stored);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("selected", selectedMenu);
  }, [selectedMenu]);
  function getlogout() {
    logout()
      .then((response) => {
        if (response.status) {
          localStorage.clear();
          navigate("/");
          message.success({ content: response.data.message });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <Layout className="min-h-screen sidebar">
      <Sider
        style={{ position: "fixed", height: "100%", overflowY: "auto" }}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <div className="demo-logo-vertical d-flex justify-content-center m-2 ">
          {!collapsed ? (
            <img
              src={images.Logo}
              alt="logo"
              className="w-full mt-9  h-28 flex justify-center  items-center rounded-lg"
            />
          ) : (
            <img
              src={images.Logo}
              alt="logo"
              className="px-2 mt-9"
              style={{ display: "flex" }}
              width={"100px"}
            />
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="text-base mt-9 pb-24"
          selectedKeys={[selectedMenu]}
        >
          <Menu.Item
            key="1"
            icon={<MdDashboard />}
            onClick={() => {
              setSelectedMenu("1");
              navigate("/dashboard");
            }}
          >
            Dashboard
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<MdOutlineSupervisedUserCircle />}
            onClick={() => {
              setSelectedMenu("2");
              navigate("/vendorsDetails", {
                state: {
                  from: "vendors",
                },
              });
            }}
          >
            Vendors Details
          </Menu.Item>
          <Menu.Item
            key="3"
            title=" Inventory Requests"
            icon={<MdRequestPage />}
            onClick={() => {
              setSelectedMenu("3");
              navigate("/inventoryRequests");
            }}
          >
            Ineventory Requests
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={<MdOutlineWallet />}
            onClick={() => {
              setSelectedMenu("4");
              navigate("/categoryDetails");
            }}
          >
            Category Details
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={<MdReport />}
            onClick={() => {
              setSelectedMenu("5");
              navigate("/vendorsDetails", {
                state: {
                  from: "reports",
                },
              });
            }}
          >
            Reports
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ marginLeft: collapsed ? 85 : 205, marginTop: 2 }}>
          <div className="flex bg-rgbaprimary3  px-2 items-center rounded-md">
            {" "}
            <Button
              type="text"
              icon={
                collapsed ? (
                  <ArrowRightOutlined className="text-rgbaprimary9" />
                ) : (
                  <ArrowLeftOutlined className="text-rgbaprimary9 " />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "22px",
                width: 50,
                height: 50,
                alignSelf: "flex-start",
              }}
            />{" "}
            {/* <p
              title="Logout"
              onClick={getlogout}
              className="bg-rgbaprimary9 border-none outline-none text-xs text-white cursor-pointer  px-4 py-2 text-center rounded-md hover:bg-bssecondary"
            >
              <LogoutOutlined />
            </p> */}
          </div>
        </Header>
        <Content
          className={`bg-rgbaprimary3 h-100 rounded-md p-3 md:p-5 ${
            collapsed ? "ml-[85px]" : "ml-[205px]"
          }`}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
