import React from "react";
import PublicAuths from "./PublicAuth";
import Login from "../Views/Auth/Login";
const publicRoutes = () => {
  return [
    {
      element: <PublicAuths />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
      ],
    },
  ];
};

export default publicRoutes;
