import { Delete, Get, Post, Put, getAPIUrl } from "../../../services/apiMethod";
import { Customersendpoint } from "./CustomerEndpoint";



export const getCustomerDetails = (param = "") => {
  const url = getAPIUrl(Customersendpoint.getCustomerDetails, param);
  return Get(url);
};
// export const updateActivationStatus = (data, param = "") => {
//   const url = getAPIUrl(Shopsendpoint.updateActivationStatus, param);
//   return Put(url, data);
// };

