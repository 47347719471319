import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import BasicLayout from "../AdminLayout";
import { Spin } from "antd";
import ErrorBoundary from "../ErrorBoundry";



function PrivateAuth(props) {
  const isAuthenticated = () => {
    const user = JSON.parse(localStorage.getItem("login"));
    return user;
  };

  return isAuthenticated() ? (
    <>
      <BasicLayout>
        <ErrorBoundary>
          <Suspense
            fallback={
              <div className="flex justify-center items-center h-screen">
                <Spin tip={<p>Loading....</p>} spinning={true}></Spin>
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </BasicLayout>
    </>
  ) : (
    <Navigate to="/" replace />
  );
}
export default PrivateAuth;
