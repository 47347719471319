import React, { useEffect, useState } from "react";
import { Modal, Form, Spin } from "antd";
import { getLocalStorageItem } from "../../../utils";

const ViewBill = ({ isOpen, setIsOpen, Fill }) => {
  const [form] = Form.useForm();
  const [pdfData, setPdfData] = useState(null);

  const createInvoice = async () => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/admin/report/fetch-bills?salesId=${Fill}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageItem("access_token")}`,
        },
      }
    );
  };
  const handleView = async (val) => {
    try {
      const response = await createInvoice();

      const buffer = await response?.arrayBuffer();

      const blob = new Blob([buffer], { type: "application/pdf" });

      const url = URL.createObjectURL(blob);
      setPdfData(url);
    } catch (err) {
      console.error("Error:", err);
    } finally {
    }
  };
  useEffect(() => {
    if (isOpen) {
      handleView();
    }
  }, [isOpen]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        footer={null}
        visible={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Bill Details"
      >
        {pdfData ? (
          <>
            <iframe
              src={pdfData}
              width="100%"
              height="600px"
              title="Bill PDF"
              frameBorder="0"
            ></iframe>
            <a href={pdfData} download="bill.pdf">
              Download PDF
            </a>
          </>
        ) : (
          <p className="flex justify-center gap-4 m-5 font-semibold text-rgbaprimary6">
            {/* <Spin /> */}
            Bill Loading...
          </p>
        )}
      </Modal>
    </>
  );
};

export default ViewBill;
