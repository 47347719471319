import { Delete, Get, Post, Put, getAPIUrl,GetBuffer } from "../../../services/apiMethod";
import { Shopsendpoint } from "./ShopsEndpoint";

export const getShopsDetails = (param = "") => {
  const url = getAPIUrl(Shopsendpoint.getShopsDetails, param);
  return Get(url);
};
export const getReportsDetails = (param = "") => {
  const url = getAPIUrl(Shopsendpoint.getReportsDetails, param);
  return Get(url);
};
export const getBillDetails = (param = "") => {
  const url = getAPIUrl(Shopsendpoint.getBillDetails, param);
  return GetBuffer(url);
};
// export const updateActivationStatus = (data, param = "") => {
//   const url = getAPIUrl(Shopsendpoint.updateActivationStatus, param);
//   return Put(url, data);
// };
