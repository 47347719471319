import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../../../src/utils/index";
import { Button, Input, message } from "antd";
import { images } from "../../../assets/images";
import { loginApi } from "./AuthapiFunctions";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({ mobile_number: "", password: "" });
  const handlelogin = () => {
    setLoading(true);
    const data = {
      mobile_number: userData.mobile_number,
      password: userData.password,
    };
    loginApi(data)
      .then((response) => {
        if (response.data.status === "success") {
          setLocalStorageItem(
            "access_token",
            response.data?.data?.access_token
          );
          setLocalStorageItem("Role", response.data?.data?.user.role);
          // setCookie("accessToken", response.data.access_token, { path: "/" });
          setLocalStorageItem("login", true);
          navigate("/dashboard");
          message.success({ content: "Admin Login Successfully" });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // When the "Enter" key is pressed, trigger the login action
      handlelogin();
    }
  };
  return (
    <div className="h-full w-full bg-gradient-to-r from-rgbaprimary5 via-rgbaprimary8 to-rgbaprimary9">
      {/* <div className=" flex justify-center items-center ">
        <p className="font-semibold text-6xl text-yellow-500 "></p>
      </div> */}
      <div className="   flex items-center justify-center h-screen flex-col  rounded-sm ">
        <div className="  p-7 w-72 md:w-1/2 lg:w-1/4  rounded-md bg-red-50 h-auto  ">
          <div className="">
            <img
              src={images.Logo}
              alt="logo"
              className="w-auto mt-3 mb-3 mx-auto h-40 flex justify-center  items-center"
            />
          </div>

          <p className=" text-center text-2xl   block text-rgbaprimary9  font-medium mb-6">
            Admin Panel
          </p>
          <div className=" my-4">
            <label
              className="block text-rgbaprimary7 text-md font-medium mb-4"
              htmlFor="username"
            >
              Mobile Number
            </label>
            <Input
              type="mobile_number"
              placeholder="Enter mobile number here..."
              name="mobile_number"
              onKeyDown={handleKeyPress}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-rgbaprimary9 text-sm leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) =>
                setUserData({ ...userData, mobile_number: e.target.value })
              }
            />
          </div>
          <div className="">
            <label
              className="block text-rgbaprimary7 text-md font-medium mb-4"
              htmlFor="password"
            >
              Password
            </label>
            <Input.Password
              placeholder="Enter password here..."
              name="password"
              onKeyDown={handleKeyPress}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-rgbaprimary9 text-sm leading-tight focus:outline-none focus:shadow-outline mb-7"
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
          </div>
          {/* <div className=" text-white  text-center font-2xl py-3 px-4 rounded focus:outline-none focus:shadow-outline"> */}
          <Button
            loading={loading}
            type="button"
            className="text-white h-10 bg-gradient-to-tr from-rgbaprimary5 to-rgbaprimary9  w-full font-semibold"
            onClick={handlelogin}
          >
            Login
          </Button>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
