import {
  Button,
  Pagination,
  Popconfirm,
  Spin,
  Table,
  Tag,
  Input,
  message,
  Space,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ExtractDate, ExtractTime, messageConfiguration } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { getReportsDetails } from "./ShopsapiFunction";
import ViewBill from "./ViewBill";
const Reports = () => {
  const [shopReportData, setShopReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [filldata, setFilldata] = useState([]);
  const searchInput = useRef(null);
  const { Search } = Input;
  const navigate = useNavigate();
  const location = useLocation();
  const shopID = location.state?.shopID;
  const vendor_Name = location.state?.vendorName;
  console.log(vendor_Name);
  function FetchReportsDetails(currentPage) {
    getReportsDetails(`shopId=${shopID}&page=${currentPage}`)
      // `isActive=1&isApproved=${approved}&isRejected=${rejected}&request_name=${searchTerm}&page=${currentPage}`
      .then((response) => {
        if (response.data.status === "success") {
          setShopReportData(response.data.data?.totalSaleRecord);
          setCurrentPage(response.data?.data?.pagination?.currentPage);
          setTotalPages(response.data?.data?.pagination?.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
    FetchReportsDetails(currentPage);
  }, [currentPage, searchTerm]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "Shop Name",
      dataIndex: "shopName",
      key: "shopName",
      ...getColumnSearchProps("shopName"),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.shopName?.toUpperCase() ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "shopDescription",
      key: "shopDescription",
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.shopDescription ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Product",
      dataIndex: "totalProduct",
      key: "totalProduct",
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.totalProduct ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.totalAmount ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      ...getColumnSearchProps("customerName"),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.customerName ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Customer Mobile Number",
      dataIndex: "customerMobileNumber",
      key: "customerMobileNumber",
      ...getColumnSearchProps("customerMobileNumber"),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.customerMobileNumber ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      ...getColumnSearchProps("customerEmail"),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.customerEmail ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Customer City",
      dataIndex: "customerCity",
      key: "customerCity",
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.customerCity ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Customer Pincode",
      dataIndex: "customerPinCode",
      key: "customerPinCode",
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.customerPinCode ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Customer Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      ...getColumnSearchProps("paymentMethod"),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.paymentMethod ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "View Bills",
      dataIndex: "view",
      key: "view",
      render: (data, record) => {
        return (
          <Button
            size="small"
            className="text-white text-xs bg-rgbaprimary9"
            onClick={() => {
              setOpenModal(true);
              setFilldata(record.salesId);
            }}
          >
            View Bills
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            {/* <div className="flex items-end justify-between m-4">
              <Search
                placeholder="search  here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "25%" }}
              />
            </div> */}

            <div
              style={{ maxHeight: "1000px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                title={() => (
                  <p className="font-medium text-xl p-5 text-primary">
                    Sales Record Details
                  </p>
                )}
                bordered={true}
                columns={columns}
                dataSource={shopReportData}
                pagination={false}
                className="w-screen sm:w-screen"
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>
            {openModal && (
              <ViewBill
                isOpen={openModal}
                setIsOpen={setOpenModal}
                Fill={filldata}
                setFill={setFilldata}
              />
            )}
          </>
        )}
      </>
    </div>
  );
};
export default Reports;
