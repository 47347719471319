import {
  Button,
  Pagination,
  Popconfirm,
  Spin,
  Table,
  Tag,
  Input,
  message,
  Space,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ExtractDate, ExtractTime, messageConfiguration } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getCustomerDetails } from "./Customerapifunction";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from '@ant-design/icons';
const Customers = () => {
  const [customerData, setcustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const { Search } = Input;
  const navigate = useNavigate();
  const location = useLocation();
  const Vendor_ID = location.state?.vendorId;
  const vendor_Name = location.state?.vendorName;
  console.log(vendor_Name);
  function FetchCustomerDetails(currentPage) {
    getCustomerDetails(`=${Vendor_ID}`)
      // `isActive=1&isApproved=${approved}&isRejected=${rejected}&request_name=${searchTerm}&page=${currentPage}`
      .then((response) => {
        if (response.data.status === "success") {
          setcustomerData(response.data.data?.fetchCustomerList);
          setCurrentPage(response.data?.data?.pagination?.currentPage);
          setTotalPages(response.data?.data?.pagination?.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
    FetchCustomerDetails(currentPage);
  }, [currentPage, searchTerm]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps('name'),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3" title={record.name}>
            {record.name?.toUpperCase() ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Mobile number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      ...getColumnSearchProps('mobileNumber'),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.mobileNumber?.toUpperCase() ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps('email'),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.email ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      ...getColumnSearchProps('city'),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.city?.toUpperCase() ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "PinCode",
      dataIndex: "pinCode",
      key: "pinCode",
      ...getColumnSearchProps('pinCode'),
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {record.pinCode ?? "--"}
          </Tag>
        );
      },
    },
    {
      title: "Created Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {ExtractDate(record.createdAt)}.{ExtractTime(record.createdAt)}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (data, record) => {
        return record.isActive === "1" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Active
          </Tag>
        ) : (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            InActive
          </Tag>
        );
      },
    },
  ];

  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="flex items-end justify-between m-4">
              <Search
                placeholder="search  here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "25%" }}
              />
            </div>

            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                title={() => (
                  <p className="font-medium text-xl p-5 text-primary">
                    Customer Details
                  </p>
                )}
                bordered={true}
                columns={columns}
                dataSource={customerData}
                pagination={false}
                className="w-screen sm:w-screen"
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};
export default Customers;
